.card_p{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0px,7px,10px, rgba(0, 0, 0, .5);
    transition: .5 ease-in-out;
    img{
        object-fit: cover;
        width: 100%;
    }
    .card_p:hover{
        transform: translate(20px);
    }
}

.card_p:hover:before{
    content: '';
    width: 100%;
    height: 100%;
    background: #080808d7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 1em;
}
.info{
    position: absolute;
    z-index: 3;
    color: white;
    opacity: 0;
    top: 0;
    transition: .5s all;
    padding: 1em;
}
.card_p:hover .info{
    opacity: 1;
    transform: translateY(3em);
}