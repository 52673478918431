//General
body{
    background-image: url(../img/fondo.png);
    background-size: cover;
    background-attachment: fixed;
    
}
.logoCarousel{
    position: absolute;
    z-index: 100;
}
.imgModalCarousel{
    padding: 1em;
    width: 90%;
}
.ModalCarousel > .modal-dialog > .modal-content{
    background-color: black;
    color: white;
}
//components
@import url(./components/_sucursales.scss);
@import url(./components/_cardg51.scss);
@import url(./components/_galeria.scss);
@import url(./components/_nav.scss);
@import url(./components/_footer.scss);
@import url(./components/_home.scss);
@import url(./components/_terminoscondiciones.scss);
@import url(./components/_aviso_privacidad.scss);
@import url(./components/_marqueeg51.scss);
//@medias
@import url(./_mediaqueries.scss);