 @media (max-width: 575.98px) { 
    body{
        font-size: 8px !important;
    }
    .logo{
        width: 2.8em;
    }
 }
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    body{
        font-size: 9px !important;
    }
    .logo{
        width: 3.2em;
    }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    body{
        font-size: 10px !important;
    }
    .logo{
        width: 3.6em;
    }
  }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    body{
        font-size: 11px !important;
    }
    .logo{
        width: 4em;
    }
 }
 @media (min-width: 1200px) and (max-width: 1399.98px) { 
    body{
        font-size: 12px !important;
    }
    .logo{
        width: 4.4em;
    }
 }
 @media (min-width: 1400px) { 
    body{
        font-size: 13px !important;
    }
    .logo{
        width: 4.8em;
    }
 }