.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav .nav-item.no-hover:hover {
  background-color: transparent;
}

.overflow-hidden {
  overflow: hidden;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  background: #000;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}

.activeMenu {
  background: lightskyblue;
  color: #000;
}

.icon,
.link_text {
  font-size: 20px;
}

.titulo-module-admin {
  color: #fff;
  background-color: #000;
  padding: 10px;
  border-radius: 10px;
}

.container-console {
  margin-left: 50px !important;
}

.home-admin-console {
  color: #fff;
  cursor: pointer;
}

.home-admin-console:link,
.home-admin-console:visited,
.home-admin-console:active {
  text-decoration: none;
  color: #fff;
}

.light {
  background-color: #fff;
  padding: 10px;
}

.form-promos-console {
  width: 80%;
}

.label-form-console {
  font-size: 1.5em !important;
  color: #000;
  text-shadow: none !important;
}

.title_cargando {
  font-size: 9em !important;
  color: white !important;
}


.title_TC{
  color: #fff;
  font-weight: bold;
}

