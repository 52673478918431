#tabPromos th{
    color: white !important;
    font-size: 1.3rem;
}
.btn-primary{
    --bs-btn-bg: #0d6efd !important;
    --bs-btn-border-color: #0d6efd !important;
    --bs-btn-hover-bg: #0b5ed7 !important;
    --bs-btn-hover-border-color: #0a58ca !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
    --bs-btn-active-bg: #0a58ca !important;
    --bs-btn-active-border-color: #0a53be !important;
    --bs-btn-disabled-bg: #0d6efd !important;
    --bs-btn-disabled-border-color: #0d6efd !important;
    width: 90%;
}
#tabPromos td{
    color: white !important;
    font-size: 1rem;
}