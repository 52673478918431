@keyframes ex {
   0% {
      bottom: 3em;
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .7));
   }

   50% {
      bottom: 2.8em;
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, .7));
   }

   100% {
      bottom: 3em;
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .7));
   }
}

.banner {
   background-color: black;
}

section {
   position: relative;
   margin-bottom: 1em;
   .btn1 {
      font-size: 3em;
      position: absolute;
      bottom: 1em;
      color: #c51424;
      transition: .5s ease-out;
      animation-name: ex !important;
      filter: drop-shadow(0px 0px 20px white);
      animation-duration: 2s;
      animation-iteration-count: infinite;
   }
}

.picometro {
   h1 {
      color: #c51424;
      text-align: center;
      margin: .5em 0em 1em 0em;
   }

   border: 2px solid black;
   box-shadow: rgba(0, 0, 0, 0.534) 0px 54px 55px,
   rgba(0, 0, 0, 0.349) 0px -12px 30px,
   rgba(0, 0, 0, 0.349) 0px 4px 6px,
   rgba(0, 0, 0, 0.301) 0px 12px 13px,
   rgba(0, 0, 0, 0.13) 0px -3px 5px;
   border-radius: .5em;
   background-color: black;

   p,
   i {
      font-size: 1.5em;
      margin-bottom: .2em;
   }

   i {
      color: #c51424;
   }
}