.containerTC{
    margin-top: 3em;
    background-color: rgb(251, 251, 62);
    padding: 1em;
    font-size: 15px;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
   // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url(../../img/bg_tc.png);
    background-image: url(../../img/bg_tc.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .lista_tc{
    margin: 2em;
  }
  .lista_tc li{
    margin-bottom: 10px;
    list-style-image: url(../../img/vinilo_point.svg);
   
  }
  .lista_tc li::marker {
   text-align: center;
    
    }
  div.post-it-note {
    padding-top:16px;
    padding-bottom:16px;
    padding-left:8px;
    padding-right:8px;
    background-color:#ffd556b0;
    box-shadow: 1px 6px 10px 1px #100f0f33;
    -webkit-transform:rotate(-2deg);
    -o-transform:rotate(-2deg);
    -moz-transform:rotate(-2deg);
    width:80%;
    height:auto;
    margin: 2em;
}
.p_ap{
    margin: 10px;
}


.title_AP{
    color: #fff;
    font-weight: bold;
}