.footer {
    font-size: 1.5em;
    color: white;
    background-color: black;
    ul {
        list-style: none;
        padding-left: 0;
    }
    .linkf {
        cursor: pointer;
        margin: .3em;
        transition: .2s;
    }
    .flicker1 a{
         color: white;
         text-decoration: none;
    }
    .linkf:hover {
        transform: scale(1.2);
        text-shadow: 0 0 5px yellow, 0 0 15px yellow, 0 0 30px yellow;
        animation: parpadear 2s infinite backwards steps(3);
    }
    h4{
        position: relative;
        padding: .3em;
    }
    h4::before{
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        border: .15em solid #c51424;
    }
    p{ position: relative; padding: .4em; display: flex; justify-content: center;}
    p::after{
        position: absolute;
        bottom: 0;
        content: '';
        width: 80%;
        border: .2em solid #c51424;
    }
    .registered{
        font-size: .4em;
    }
}



@keyframes parpadear {
    to {
        color: gray;
        text-shadow: none;
    }
}