.card {
    background-color: black !important;
}

.info-sucursal {
    position: relative !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: 2s all !important;

    img {
        object-fit: cover;
        width: 100%;

    }
}

.textInfo {
    position: absolute;
    z-index: 3;
    color: white;
    top: 0;
    padding: 1em;
    opacity: 0;
    transition: .8s all !important;
    transform: translateY(50%);
}

.info-sucursal:hover:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #080808d7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 1em;
    transition: .8s all !important;
}

.info_sucursal:hover {
    transform: translateY(20px);
}

.info-sucursal:hover .textInfo {
    opacity: 1;
    transform: translateY(0%)
}

.sucHorarios {
   
    color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    H6{
        color: white;
    }
    .tab_horarios{
        padding: 1em !important;
        width: 60%;
        background-color: white;
            td:nth-child(1){
                padding-left: 1em
            }
    }
}
.datosSuc::before{
    content: '';
    width: 100%;
    background-color: red;
    height: .5em;
}
.icon-apps{
    width: 3em !important;
    margin-right: 1em;
}
.card-titleSuc{
    position: absolute;
    background-color: black;
    width: 100%;
    color: white;
    text-align: center;
    padding: .5em;
}