$face-color: #576b95;
$tw-color: #1a8cd8;
$whats-color: #25d366;
$insta-color: #e7108d;

@keyframes selectRedes {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(-10px)
    }

    100% {
        transform: translateY(0px)
    }
}

@keyframes parpadear {
    to {
        color: gray;
        text-shadow: none;
    }
}

.nav {
    font-size: 1.5em;
    margin-bottom: 2.5em !important;

    .logo {
        filter: drop-shadow(0px 0px 20px white) !important;

    }

    ul {
        list-style: none;
        color: white;

        li {
            padding: .5em;
            width: 1.3em;
            height: 1.3em;
            border-radius: 100%;
            margin-right: 0.3em;
            font-size: 1.5em;
            transition: 0.5s;
            margin-bottom: 0;
        }

        li:hover {
            cursor: pointer;
            border: 1px solid #c51424;
            animation-name: selectRedes;
            animation-duration: 1s;
            animation-iteration-count: infinite;
        }

    }
}

.active {
    text-shadow: 0 0 5px #c51424, 0 0 15px #c51424, 0 0 30px #c51424 !important;
    animation: parpadear 2s infinite backwards steps(3);
}

.colorFace {
    background-color: $face-color;
}

.colorTwitter {
    background-color: $tw-color;
}

.colorWhats {
    background-color: $whats-color;
}

.colorInsta {
    background-color: $insta-color;
}